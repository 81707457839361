import firebase from "firebase/app";
import "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCtSbfm6MVsF3zwKYMusuoxxshIQZHNUho",
  authDomain: "kovalsoft-com.firebaseapp.com",
  projectId: "kovalsoft-com",
  storageBucket: "kovalsoft-com.appspot.com",
  messagingSenderId: "1012146504891",
  appId: "1:1012146504891:web:f5d0ac006607a98083d59e",
  measurementId: "G-G5WBB3N2MT",
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();
}
