import { merge } from "theme-ui";
// @ts-ignore
import { funk } from "@theme-ui/presets";

export const theme = merge(funk, {
  fonts: {
    body: `"Poppins", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif`,
  },
});

export default theme;
